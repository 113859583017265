<template>
  <subview>
    <view-header>
      <custom-header :router-back="goToCompetitorsPage">
        <template v-slot:default>
          <div>
            <Icon :size="24" type="arrow-left"/>
            External Link Generator
          </div>
        </template>
      </custom-header>
    </view-header>

    <view-content>
      <h3>Create Personalized Invite Link</h3>
      <p class="page-description">Fill in the text boxes and create a personalized invitation for a specific attendee.
        Copy the link or share in
        messengers</p>
      <div class="group input-group">
        <label class="required-input">Name of Invitee</label>
        <input v-model.trim="linkForm.username" type="text" name="full-name" placeholder="Full Name" required/>
      </div>
      <div class="group input-group">
        <label class="non-require">Message to Invitee</label>
        <a-textarea v-model.trim="linkForm.description" required placeholder="Invitation text"/>
        <span class="textarea-counter">{{ linkForm.description.length + '/500' }}</span>
      </div>
      <span class="copied-link-message" v-if="isUrlCopied">
        <Icon :size="24" :type="'check'" />
        Link Copied to Clipboard
      </span>
    </view-content>

    <view-footer>
      <div class="links-wrap">
        <a-button :class="{ disabled: !isWeHaveFormData}" @click="copyLink">
          <svg-icon name="copy-link-icon"/>
          Copy Link
        </a-button>
        <a-button class="secondary" @click="goToCompetitorsPage">
          Close
        </a-button>
      </div>
    </view-footer>
  </subview>
</template>

<script>
import Icon from '@/components/Icon'
import newcompetition from '@/mixins/newcompetition'
import { copyTextToClipboard } from "@/helpers";

const md5 = require('md5')

export default {
  name: "ExternalLink",
  mixins: [newcompetition],
  components: {
    Icon
  },
  data() {
    return {
      linkForm: {
        username: '',
        description: '',
      },
      isUrlCopied: false
    }
  },
  computed: {
    isWeHaveFormData() {
      // return this.linkForm.username && this.linkForm.description
      return !!this.linkForm.username
    }
  },
  methods: {
    copyLink() {
      const dataForHash = Object.values(this.linkForm).join(',')
      const hash = md5(dataForHash)
      const alreadyInvitedUser = this.invitesByLink.find(item => {
        return item.username.toLowerCase() === this.linkForm.username.toLowerCase()
      })

      if (!alreadyInvitedUser) {
        this.setInvitesByLink({...this.linkForm, hash: hash})
        copyTextToClipboard(`${window.location.origin}/details/${hash}`)
        this.linkForm = { username: '', description: '' }
        this.isUrlCopied = true
        setTimeout(() => {
          this.isUrlCopied = false
          this.goToCompetitorsPage();
        }, 1000)
      }
    },
    goToCompetitorsPage() {
      this.$router.push({name: "newcompetition.competitors"});
    }
  }
}
</script>

<style lang="stylus" scoped>
.links-wrap {
  display: flex;

  & svg {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
}

.input-group {
  margin-bottom: 1rem;
  position: relative;
}

  &:last-child {
    margin-bottom: 0;
  }

  & input,
  & textarea {
    font-size: 16px;
    padding: 14px 16px;
    display: block;
    width: 100%;
    border: none;
    background: #0b0d13;
    border-radius: 8px;
    color: #d8d8d8;
    -moz-appearance: textfield;

    &::placeholder {
      color: #7B8B97;
    }
  }

  & textarea {
    min-height: 160px;
  }

  & label {
    font-weight: 400;
    line-height: 20px;
    opacity: 0.8;
    padding-left: 1rem;
    margin-bottom: 10px;
    font-size: 14px;
    color: #FFFFFF;

    &:after {
      content: " *";
      color: #FFFFFF;
      opacity: 0.8
    }

    &.non-require {
      &:after {
        content: none;
      }
    }
  }


  & .textarea-counter {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: #7B8B97;
    position: absolute;
    bottom: 12px;
    right: 1rem;
    user-select: none;
    pointer-events: none;
  }

h3 {
  font-size: 18px;
  color: #EEEEEE;
  margin: 12px 0 8px;
  line-height: 24px;
  letter-spacing: 0.01em;
  font-weight: 600;
}

.page-description {
  font-size: 12px;
  line-height: 133%;
  color: #7B8B97;
  margin-bottom: 2rem;
  font-weight: 400;
}

.ant-btn[disabled].secondary {
  color: white;
}

.view-content {
  position: relative;
}

.copied-link-message {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 5px;
  width: 280px;
  padding: 14px 16px;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  background: #6293FF;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;

  & svg {
    width: 10px;
    height: 10px;
  }
}
</style>
